import { createSlice } from "@reduxjs/toolkit";

// Check if there is any data saved in localStorage and set it as the initial state
const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem("data");
  return savedState ? JSON.parse(savedState) : {};
};

const initialState = loadStateFromLocalStorage();

export const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

// Save the state to localStorage whenever it's updated
const saveStateToLocalStorage = (state) => {
  localStorage.setItem("data", JSON.stringify(state));
};

// Add a listener to automatically save state on any change
export const { addData } = DataSlice.actions;
export const dataReducer = (state, action) => {
  const newState = DataSlice.reducer(state, action);
  saveStateToLocalStorage(newState); // Persist the new state
  return newState;
};

export default dataReducer;
