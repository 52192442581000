import axios from "axios";
import { store } from "../Slices";
import { setAuth } from "../Slices/AuthSlice";
import { addData } from "../Slices/DataSlice";
import { Activescreen } from "../Slices/Activescreen";
import Swal from "sweetalert2";

// Function to get token from Redux store
const getToken = () => {
  const token = store.getState()?.data?.userInfo?.data;
  return token?.token || null;
};

const handleUnauthorized = () => {
  Swal.fire({
    icon: "warning",
    title: "Session Expired",
    text: "Your session has expired. Please log in again.",
    confirmButtonText: "OK",
  });

  const dispatch = store.dispatch;
  dispatch(setAuth(false));
  dispatch(addData({ name: "practice", data: [] }));
  dispatch(addData({ name: "userInfo", data: [] }));
  dispatch(Activescreen("Dashboard"));
};

const callApi = async (
  endpoint,
  method,
  payload,
  malter = false,
  objnew = false
) => {
  const token = getToken();

  const authHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  let configaxios;

  if (malter) {
    const formData = new FormData();

    payload?.files?.forEach((file) => {
      formData.append("files", file);
    });
    Object.keys(payload || {}).forEach((key) => {
      if (key !== "files") {
        formData.append(key, payload[key]);
      }
    });

    configaxios = {
      method,
      url: endpoint,
      data: formData,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
        ...authHeaders,
      },
    };
  } else {
    configaxios = {
      method,
      url: endpoint,
      data: payload,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        ...authHeaders,
      },
    };

    if (objnew) {
      configaxios.responseType = "blob";
    }
  }

  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleUnauthorized();
        }
        reject(error?.response?.data || { message: "Request failed" });
      });
  });
};

const callPublicApi = (endpoint, method, payload) => {
  const configaxios = {
    method,
    url: `${process.env.REACT_APP_AuthenticationService}${endpoint}`,
    data: payload,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        if (res?.data) {
          resolve(res.data);
        } else {
          reject({ message: "No data returned from API" });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleUnauthorized();
        }
        reject({
          message:
            error?.response?.data?.message ||
            "Something went wrong, please re-login",
          statusCode: error?.response?.status || 500,
        });
      });
  });
};

export { callApi, callPublicApi };
