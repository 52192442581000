import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoader from "./components/common/ScreenLoader";
import { callApi } from "./components/common/Apicall";
import { addData } from "./components/Slices/DataSlice";
import { Toaster } from "react-hot-toast";
import { Activescreen } from "./components/Slices/Activescreen";

const App = () => {
  const Login = lazy(() => import("./components/Login/Login"));
  const SearchedTicket = lazy(() =>
    import("./components/SearchedTicket/SearchedTicket")
  );
  const Main = lazy(() => import("./components/AddScreens/Main/Main"));
  const Dashboard = lazy(() =>
    import("./components/Header/Dashboard/Dashboard")
  );

  const initialImportModel = {
    perPage: 10,
    pageNo: 1,
    practiceId: null,
    totalPages: 0,
    totalCount: 0,
    departmentId: null,
    teamId: null,
    totalRows: "",
    id: "",
    priorityId: "",
    taskResponsibilityId:'',
    categoryId: "",
    name: "",
    statusId: "",
    createdFrom: "",
    createdTo: "",
  };
  
  const [state, setState] = useState({
    importModel: initialImportModel,
    addPopup: "",
    totalRows: 50,
    rows: [],
    roles: [],
    modalRow: null,
  });
  const dispatch = useDispatch();
  const activeScreen = useSelector((state) => state.activeScreen);
  const message = useSelector((state) => state.data?.userInfo?.data);
   
  const [loader, setLoader] = useState(false);

  const components = {
    SearchedTicket: SearchedTicket,
    Main: Main,
    Dashboard: Dashboard,
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      importModel: {
        ...state.importModel,
        [name]: value?.toString(),
      },
    });
    if (name == "priorityId") {
      return;
    } else {
      trigger();
    }
  };
  
  useEffect(() => {
    if (state.importModel.priorityId) {
      trigger();
    }
  }, [state.importModel.priorityId]);
  const trigger = () => {
    dispatch(Activescreen("SearchedTicket"));
  };
  const URL = process.env.REACT_APP_URL_DOCUHUB_URL;
  const DisplayComponent = components[activeScreen?.ActiveScreen];

  useEffect(() => {
    if (message?.message === "Login Successfully") {
      getData();
    }
  }, [message]);

  const getData = () => {
    setLoader(true);
    Promise.all([   callApi(`${URL}/Department/GetDepartmentDropDown`, "get"),
      callApi(`${URL}/Responsibility/GetResponsibilityDropDown`, "get")
    ])
 
      .then((res) => {
        const data={...res[0].sideBarOptions,...res[1].sideBarOptions}
        dispatch(addData({ name: "practice", data: data }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <Toaster />
      <div className="flex flex-col min-h-screen font-sans bg-[#FFF]">
        <Suspense fallback={<ScreenLoader />}>
          {message?.token && DisplayComponent ? (
            <DisplayComponent
              {...(activeScreen?.ActiveScreen === "SearchedTicket" ||
              activeScreen?.ActiveScreen === "Dashboard"
                ? {
                    handleChange,
                    dashboardstate: { ...state },
                    setdashboardstate: { setState },
                  }
                : { payload: null })}
            />
          ) : (
            <Login />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default App;
